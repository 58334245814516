@import '../../assets/scss/variables.scss';

.operations-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 70px;
    width: 100%;
    max-width: 1200px;
    background-color: lighten($primaryColor, 50%);
    margin: 0 auto 70px auto;
    font-size: 14px;

    @media(min-width: 1024px) {
        font-size: 16px;
    }

    &__balance {
        line-height: 80px;
        height: 80px;
        width: 45%;

        color: $primaryColor;
        margin-top: 10px;

        span {
            font-size: 16px;

            @media(min-width: 1024px) {
                font-size: 18px;
            }
        }
    }

    &__periode-balance {

        line-height: 80px;
        height: 80px;
        width: 45%;
        color: $primaryColor;
        margin-top: 10px;
        text-align: end;

        span {
            font-size: 16px;

            @media(min-width: 1024px) {
                font-size: 18px;
            }
        }
    }

    &__inputs {
        display: flex;
        flex-wrap: wrap;
        height: 30px;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
    }

    &__input {
        width: 44% !important;

    }
}
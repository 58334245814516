@import '../../../assets/scss/variables.scss';

.input-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;

    @media(min-width: 700px) {
        // width: 44%;
    }

    .label {
        margin-top: 20px;
        font-size: 12px;
        text-transform: capitalize;
    }

    .input {
        height: 30px;
        outline: none;
        border: 1px solid black;
        border-radius: 5px;
        padding: 0 4px;
        font-family: $primaryFont;
    }
}
@import '../../assets/scss/variables.scss';

.operations {

    max-width: 1200px;
    margin: 0 auto;
    position: relative;

    &__list {
        padding: 0;
        width: 90%;
        margin: 80px auto;
        list-style: none;


        li {
            padding: 10px 20px;
        }

        li:nth-child(even) {
            background-color: #eeeeee;

        }
    }

    &__add-button {
        position: absolute;
        right: 50px;
        bottom: 40px;
        fill: $primaryColor;
        font-size: 50px;
        cursor: pointer;
    }

    &__search {
        width: 95%;
        max-width: 380px;
    }
}
@import '../../../assets//scss/variables.scss';

.link {
    text-decoration: none;
    color: $primaryColor;
    text-transform: uppercase;
    font-size: 14px;

    @media(min-width: 1024px) {
        font-size: 16px;
    }
}

.active-link {
    font-weight: bold;
}
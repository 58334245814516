@import '../../../assets/scss/variables.scss';

.group {
    display: flex;
    height: 60px;
    align-items: center;
    padding: 0 10px;

    &__name {
        margin-left: 10px;
        text-transform: capitalize;
        font-size: 14px;
    }

    &__icon {

        font-size: 25px;
        padding: 10px;
        color: white;
        border-radius: 50%;

    }
}
@import '../../assets/scss/variables.scss';

.periods {
    display: flex;
    justify-content: space-around;
    height: 30px;
    width: 90%;
    margin: 0 auto 20px auto;
    max-width: 390px;

    font-size: 14px;

    @media(min-width: 1024px) {
        font-size: 16px;
    }


    .period {
        background-color: lighten($primaryColor, 30%);
        border-radius: 5px;
        width: 85px;
        color: white;
        text-transform: capitalize;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
    }

    .active {
        background-color: $primaryColor;
    }
}
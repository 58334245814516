@import '../../assets/scss/variables.scss';

.app-header {
    height: 60px;
    padding: 5px;
    background-color: $primaryColor ;
    box-shadow: 8px 8px 24px -17px rgba(66, 68, 90, 1);

    @media(min-width: 1024px) {
        height: 80px;
    }
}
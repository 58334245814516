@import '../../assets/scss/variables.scss';

.main {
    max-width: 1200px;
    margin: 0 auto;

    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: 70px;
        width: 100%;
        max-width: 1200px;
        background-color: lighten($primaryColor, 50%);
        margin: 0 auto;
        font-size: 14px;
    }

    &__select {
        width: 95%;
        max-width: 80px;
        margin-bottom: 30px;

        .option {
            line-height: 25px;
        }
    }
}
@import '../../assets/scss/variables.scss';

.operation-form {

    &__type {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 10px;
        background-color: lighten($primaryColor, 40%);

        .type-button {
            box-sizing: border-box;
            text-transform: uppercase;
            width: 50%;
            padding: 10px 0px;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
        }

        .active {
            font-weight: bold;

        }
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 400px;
        background-color: lighten($primaryColor, 40%);

        .save-button {
            background-color: $primaryColor;
            color: White;
            width: 45%;
            margin: 60px auto;

        }

        .delete {
            background-color: rgb(99, 6, 6);
        }
    }
}
@import '../../assets/scss/variables.scss';

.navigation {
    ul {
        list-style: none;
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 20px 20px;

        li {
            flex-basis: 200px;
            text-align: center;
            display: block;
            margin: 0;

            a {
                margin: 0;
                display: block;
                width: 100%;
            }
        }
    }

}